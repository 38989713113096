<template>
  <app-card-wizard title="Üyelik Kontrolü" v-if="currentView === 0">
    <template v-slot:description>
      <p>
        Başvurunuza başlamadan önce isterseniz firmanız adına Türkiye genelinde
        hali hazırda bir ihracatçı birliğine üyeliği var mı onu kontrol edelim.
      </p>
      <app-form-group
        :validator="validator"
        name="companyType"
        label="Firma Türü"
      >
        <p-dropdown
          class="company-type"
          style="width:100%;"
          v-model="companyType"
          :options="companyTypes"
          :showClear="true"
          optionLabel="name"
          optionDisabled="disabled"
          dataKey="name"
          placeholder="Firma türünü seçin"
          @change="
            updateCompanyInfo($event.originalEvent, 'companyType', $event.value)
          "
        >
          <template v-once #option="{ option }">
            <span
              :class="{
                'text-semi-bold parent-option': !option.parent,
                'child-option': option.parent
              }"
              >{{ option.name }}</span
            >
          </template>
        </p-dropdown>
      </app-form-group>

      <app-form-group
        :validator="validator"
        name="taxNumber"
        :label="tax.label"
      >
        <input
          name="taxNumber"
          class="form-control"
          v-model="taxNumber"
          v-numeric
          :maxlength="10"
          :placeholder="tax.placeholder"
          @change="updateTaxAndIdentityNumber"
        />
      </app-form-group>

      <app-form-group
        v-if="isSoleCompany"
        :validator="validator"
        name="identityNumber"
        label="T.C. Kimlik Numarası"
      >
        <input
          name="identityNumber"
          class="form-control"
          v-model="identityNumber"
          v-numeric
          maxlength="11"
          placeholder="T.C. Kimlik Numarası girin"
          @change="updateTaxAndIdentityNumber"
        />
      </app-form-group>

      <app-form-group
        :validator="validator"
        name="applicantType"
        label="Başvuruyu Yapan"
        help="Üyelik başvurusunu yapan kişinin kim olduğunu belirtin."
      >
        <p-dropdown
          style="width:100%;"
          v-model="applicantType"
          :options="applicantTypes"
          :showClear="true"
          dataKey="name"
          scrollHeight="270px"
          @change="onApplicantTypeChange"
          optionLabel="title"
          optionValue="name"
          placeholder="Üye kaydını başvurusunu kimin yaptığını seçebilir misiniz?"
          :disabled="applicationData.isOnlineUser"
        >
          <template #option="slotProps">
            <span>{{ slotProps.option.title }}</span>
            <div class="p-dropdown-desc small">
              <span>{{ slotProps.option.description }}</span>
            </div>
          </template>
        </p-dropdown>
      </app-form-group>
      <app-wizard-buttons
        :next-button-disabled="isLoading"
        :next-button-is-loading="isLoading"
        next-button-text="Sorgula"
        @next-button-click="onSubmit"
        :prev-button-visible="true"
        :prev-button-disabled="isLoading"
        @prev-button-click="redirect('/validation/' + applicationData.stateId)"
      ></app-wizard-buttons>
    </template>
  </app-card-wizard>
  <template v-else-if="companyExists">
    <app-card-wizard title="Üyelik Kontrolü">
      <app-title-desc
        title="Zaten aktif bir üyeliğiniz var"
        class="title-lg"
        style="padding-top:30px;"
        v-if="hasActiveMembership"
      >
        Belirttiğiniz
        <strong>{{ membershipQueryResult.taxNumber }}</strong> vergi kimlik
        numarası ile <strong>{{ companyName }}</strong> adına kayıtlı aşağıdaki
        ihracatçı
        {{ memberships.length == 1 ? "birliğine" : "birliklerinde" }}
        kayıtlı üyeliğiniz var.
      </app-title-desc>
      <app-title-desc
        title="Zaten bir üyeliğiniz var"
        class="title-lg"
        style="padding-top:30px;"
        v-if="!hasActiveMembership"
      >
        <template v-if="isBrokerRegistration">
          <div>
            Belirttiğiniz
            <strong>{{ membershipQueryResult.taxNumber }}</strong> vergi kimlik
            numarası ile <strong>{{ companyName }}</strong> adına kayıtlı
            e-birlik sisteminde bir gümrük müşaviri üyeliği zaten bulunuyor.
          </div>

          <app-wizard-buttons
            :next-button-visible="false"
            :prev-button-visible="true"
            :prev-button-disabled="isLoading"
            @prev-button-click="goBack"
          ></app-wizard-buttons>
        </template>
        <template v-if="!isBrokerRegistration">
          Belirttiğiniz
          <strong>{{ membershipQueryResult.taxNumber }}</strong> vergi
          <span v-if="membershipQueryResult.taxNumber.length === 11"
            >T.C. Kimlik</span
          >
          <span v-if="membershipQueryResult.taxNumber.length === 10"
            >vergi</span
          >
          numarası ile <strong>{{ companyName }}</strong> adına kayıtlı
          aşağıdaki ihracatçı
          {{ memberships.length == 1 ? "birliğine" : "birliklerinde" }}
          kayıtlı kapalı üyeliğiniz var. Dilerseniz bu üyeliğinizi tekrar
          açabilirsiniz ya da yeni bir üyelik başvurusunda bulunabilirsiniz
        </template>
      </app-title-desc>
      <template v-slot:footer v-if="hasMembership">
        <table class="table table-gold table-in-card table-striped">
          <thead>
            <tr>
              <th scope="col" class="no-wrap">BİRLİK</th>
              <th scope="col" class="no-wrap">GİRİŞ TARİHİ</th>
              <th scope="col" class="no-wrap">SİCİL NO</th>
              <th scope="col" class="no-wrap">DURUM</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in memberships"
              v-bind:key="item"
              :class="{ clickable: item.url }"
              @click="openUrl(item.url)"
            >
              <td>
                <div>{{ item.name }}</div>
              </td>
              <td>
                {{ $moment(item.regDate, "YYYY-MM-DD").format("DD.MM.YYYY") }}
              </td>
              <td>{{ item.regNumber }}</td>
              <td>
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="flex-wrap: nowrap;"
                >
                  <span v-if="item.isActive">Açık</span>
                  <span
                    v-if="!item.isActive"
                    v-tooltip.left="'Detaylı bilgi alın'"
                    >Kapalı
                  </span>
                  <i class="fa fa-question-circle" v-if="!item.isActive"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </app-card-wizard>
    <app-card-wizard
      title="Online İşlemler hesabınız var"
      class="card-blue card-title-lg"
      v-if="hasOnlineMembership && !hasMembershipWithDebt"
      :show-progress="false"
    >
      <template v-slot:description>
        <p>
          Bu firma adına <strong>{{ email }}</strong> adresile
          <strong>Online İşlemler</strong> hesabınız da bulunuyor.
          Dilerseniz hesabınız ile Online İşlemeler Sistemine giriş
          yapabilirsiniz.
        </p>
        <a :href="timOnlineUrl" target="_blank" class="btn btn-primary-dark"
          >Online İşlemlere Giriş Yapın</a
        >
      </template>
    </app-card-wizard>
    <app-card-wizard
      title="Online İşlemler Hesabı Açın"
      v-if="
        !hasOnlineMembership && hasActiveMembership && !hasMembershipWithDebt
      "
      :show-progress="false"
    >
      <template v-slot:description>
        <p>
          En az bir birliğe aktif üyeliğiniz olduğu için başka bir sektörde
          ihracat yapmak istiyor olsanız dahi yeniden bir üyelik yapmanıza gerek
          olmadığını bilmenizi isteriz. Fakat yine de başka bir birliğe üye
          olmak isterseniz buradan başvuru adımlarına devam edebilirsiniz.
        </p>
        <a
          :href="timOnlineUrl"
          target="_blank"
          class="btn btn-primary-dark text-nowrap"
          >Online İşlemler Hesabı Açın</a
        >
      </template>
    </app-card-wizard>
    <app-card v-if="hasMembershipWithDebt" type="danger">
      <app-title-desc
        title="Borcunuzdan ötürü başka bir birliğe üye olamazsınız"
        class="danger"
      >
        <p>
          Aşağıda bilgileri belirtilmiş olan birliklere olan borcunuzdan ötürü
          yeni bir üyelik başvurusu yapamazsınız.
        </p>

        <div v-for="item in membershipsWithDebt" :key="item.id" class="pt-4">
          <div style="color:#FFF; font-size:18px; font-weight:600;">
            {{ item.name }}
          </div>
          <div>
            <a
              :href="item.url"
              target="_blank"
              style="color:rgba(255,255,255,0.7)"
              >Detaylı bilgi al</a
            >
          </div>
        </div>

        <button type="button" class="btn btn-light mt-5" @click="goBack">
          Önceki Adıma Geri Dön
        </button>
      </app-title-desc>
    </app-card>

    <app-card v-if="hasMembership && !hasMembershipWithDebt">
      <app-title-desc title="Başka Bir Birliğe Üye Olun" class="turquoise">
        <p>
          En az bir birliğe aktif üyeliğiniz olduğu için başka bir sektörde
          ihracat yapmak istiyor olsanız dahi yeniden bir üyelik yapmanıza gerek
          olmadığını bilmenizi isteriz. Fakat yine de başka bir birliğe üye
          olmak isterseniz buradan başvuru adımlarına devam edebilirisiniz.
        </p>

        <app-wizard-buttons
          :next-button-disabled="isLoading"
          :next-button-is-loading="isLoading"
          next-button-text="Başvuruya Devam Et"
          @next-button-click="goToNextStep"
          :prev-button-visible="prevButtonVisible"
          :prev-button-disabled="isLoading"
          @prev-button-click="goBack"
        ></app-wizard-buttons>
      </app-title-desc>
    </app-card>
  </template>
</template>

<script>
import { settings, rules, companyTypes, slugify } from "../shared";
import apiClient from "../services";
//import { required, helpers } from "@vuelidate/validators";

export default {
  name: "check-membership",
  data() {
    const applicantTypes = Object.keys(settings.applicantTypes).map((key) => {
      return settings.applicantTypes[key];
    });

    return {
      taxNumber: null,
      identityNumber: null,
      applicantType: null,
      currentView: 0,
      selectedApplicantType: null,
      isLoading: false,
      applicantTypes,
      companyType: null,
      membershipQueryResult: {}
    };
  },
  validations: {
    companyType: rules.required,
    taxNumber: rules.taxNumber,
    identityNumber: rules.identityNumber,
    applicantType: rules.required
  },
  methods: {
    updateTaxAndIdentityNumber($event) {
      if (this.isSoleCompany) {
        this.updateApplicationData({ taxNumber: this.identityNumber });
        this.updateCompanyInfo($event, "taxNumber", this.identityNumber);
        this.updateCompanyInfo($event, "soleCompanyTaxNumber", this.taxNumber);
      } else {
        this.updateApplicationData({ taxNumber: this.taxNumber });
        this.updateCompanyInfo($event, "taxNumber", this.taxNumber);
        this.updateCompanyInfo($event, "soleCompanyTaxNumber", null);
      }
    },
    onCompanyTypeChange($event) {
      this.updateCompanyInfo(
        $event.originalEvent,
        "companyType",
        this.companyType
      );
    },
    updateCompanyInfo($event, name, value) {
      name = name || $event.target.name;

      if (name) {
        const companyInfo = this.applicationData.companyInfo || {};
        companyInfo[name] = value || $event?.target?.value;
        this.updateApplicationData({ companyInfo });
      }
    },
    prevButtonVisible() {
      return !this.applicationData.fromReview || this.currentView === 1;
    },
    nextButtonText() {
      return this.applicationData.fromReview ? "Kaydet" : "Devam Et";
    },
    goBack() {
      this.currentView = 0;
    },
    goToNextStep() {
      this.markStepAsCompleted(this.stepName);

      const nextStep = this.isBrokerRegistration
        ? "/applicant/"
        : "/choose-association/";

      this.goTo(nextStep + this.applicationData.stateId);
    },
    onSubmit() {
      /*
    companyType: rules.required,
    taxNumber: rules.taxNumber,
    identityNumber: rules.identityNumber,
    applicantType: rules.required
*/

      this.validator.$touch();

      const isIdentityNumberValid =
        !this.isSoleCompany || !this.validator.identityNumber.$invalid;

      if (
        this.validator.companyType.$invalid ||
        this.validator.applicantType.$invalid ||
        this.validator.taxNumber.$invalid ||
        !isIdentityNumberValid
      ) {
        this.scrollToError();
        return;
      }

      this.isLoading = true;

      apiClient
        .checkMemberships(
          this.applicationData.email,
          this.applicationData.companyInfo.taxNumber,
          this.applicationData.registrationType,
          this.applicationData.companyInfo.soleCompanyTaxNumber
        )
        .then((response) => {
          const applicant = this.applicationData.applicant || { type: null };
          applicant.type = this.applicantType;
          const taxNumber = this.isSoleCompany
            ? this.identityNumber
            : this.taxNumber;
          this.membershipQueryResult = response?.data || {};

          const memberships = [
            ...(this.membershipQueryResult.memberships || [])
          ];

          const companyInfo = this.applicationData.companyInfo;

          if (this.isSoleCompany) {
            companyInfo.soleCompanyTaxNumber = this.taxNumber;
            companyInfo.taxNumber = this.identityNumber;
          } else {
            companyInfo.soleCompanyTaxNumber = null;
            companyInfo.taxNumber = this.taxNumber;
          }

          if (
            !companyInfo.companyName &&
            this.membershipQueryResult.companyName
          ) {
            companyInfo.companyName = this.membershipQueryResult.companyName;
          }

          const hasOnlineMembership =
            this.membershipQueryResult.hasOnlineMembership ||
            this.applicationData.isOnlineUser;

          this.updateApplicationData({
            taxNumber,
            applicant,
            memberships,
            companyInfo,
            hasOnlineMembership
          });

          if (hasOnlineMembership) {
            this.updateApplicationData({
              createUserAccount: false
            });
          }

          if (
            !this.applicationData.hqAddress &&
            this.membershipQueryResult.hqAddress
          ) {
            this.updateApplicationData({
              hqAddress: this.membershipQueryResult.hqAddress,
              hqCity: slugify(this.membershipQueryResult.hqAddress.city)
            });
          }

          if (this.companyExists && this.hasMembership) {
            const currentStep = this.applicationData.currentStep;
            currentStep.view = this.currentView = 1;
            this.updateApplicationData({ currentStep });
          } else {
            this.goToNextStep();
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Üyelik Sorgulanamadı",
            html:
              "Üyelik sorgulaması yapılırken beklenmedik bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
            confirmButtonText: "Tamam"
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    onApplicantTypeChange(event) {
      const val = event.value;

      if (val && val === "broker") {
        this.$swal({
          icon: "info",
          title: "Online İşlemlerden Başvuru Yapın",
          html:
            "Gümrük müşaviri olarak vekaleten başka bir firma adına başvuruda bulunabilmek için Online İşlemler'e giriş yaparak başvuru sürecini Online İşlemler'den başlatmanız gerekmektedir.",
          confirmButtonText: "Online İşlemler'e Git",
          showCancelButton: true,
          cancelButtonText: "Vazgeç"
        }).then((result) => {
          if (result.isConfirmed) {
            window.parent.location.href = this.timOnlineUrl;
          } 

          this.applicantType = null;
        });
      }

      const applicant = this.applicationData?.applicant || {};
      applicant.type = val && val === "broker" ? null : val;
      this.updateApplicationData({ applicant });
    }
  },
  computed: {
    isSoleCompany() {
      return this.companyType && this.companyType.value === "shsfrm";
    },
    isForeignSoleCompany() {
      return this.companyType && this.companyType.value === "ybncshsfrm";
    },
    companyExists() {
      return !!this.companyName;
    },
    tax() {
      return this.isSoleCompany
        ? {
            label: "Vergi Kimlik Numarası",
            placeholder: "Şahıs firması vergi numarasını girin"
          }
        : {
            label: "Vergi Numarası",
            placeholder: "Firma Vergi Numarası giriniz"
          };
    },
    companyTypes() {
      return companyTypes;
    },
    validator() {
      return this.$v;
    },
    stepName() {
      return "check-membership";
    },
    memberships() {
      return this.applicationData.memberships || [];
    },
    activeMemberships() {
      return this.memberships.filter((m) => m.isActive);
    },
    hasMembership() {
      return this.memberships.length > 0;
    },
    hasActiveMembership() {
      return this.memberships.some((m) => m.isActive);
    },
    hasMembershipWithDebt() {
      return this.memberships.some((m) => m.hasDebt);
    },
    membershipsWithDebt() {
      return this.memberships.filter((m) => m.hasDebt);
    },
    companyName() {
      return this.membershipQueryResult?.companyName;
    },
    hasOnlineMembership() {
      return (
        this.membershipQueryResult &&
        this.membershipQueryResult.hasOnlineMembership
      );
    },
    timOnlineUrl() {
      return "https://online.tim.org.tr";
    }
  },
  beforeMount() {
    const appData = this.applicationData || {};
    const companyInfo = appData.companyInfo || {};

    this.companyType = companyInfo.companyType;
    this.applicantType = appData.applicant?.type;
    this.memberships = appData.memberships;

    if (this.isSoleCompany) {
      this.identityNumber = companyInfo.taxNumber;
      this.taxNumber = companyInfo.soleCompanyTaxNumber;
    } else {
      this.taxNumber = companyInfo.taxNumber;
      this.identityNumber = null;
    }

    if (appData.registrationType === "broker") {
      this.applicantTypes = this.applicantTypes.filter(
        (t) => t.name != "broker"
      );
    }

    this.markStepAsInCompleted(this.stepName);
  }
};
</script>

<style lang="scss">
@import "../scss/_mixins.scss";
@import "../scss/_variables.scss";

.company-type {
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    &.p-disabled {
      color: #212529;
      opacity: 1;
      cursor: default;
    }

    .child-option {
      padding-left: 1rem;
    }

    .parent-option {
      padding: 0;
    }
  }
}

.table-in-card {
  background: $gold;
  border-collapse: collapse;
  border-radius: $border-radius-lg;
  overflow: hidden;
  margin: 0;

  th,
  td {
    padding: 17px 30px;
    font-size: 14px;
    color: #fff;
    border: none;
  }

  thead {
    th {
      color: rgba(255, 255, 255, 0.7);
      font-size: 13px;
      font-weight: $font-weight-normal;
      padding: 13px 30px;
      border: none;
      white-space: nowrap;
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background: rgba(255, 255, 255, 0.15);
    }
  }

  tr.clickable {
    @include transition();

    &:hover {
      background: rgba(0, 0, 0, 0.15);
    }
  }
}
</style>
